






// Import utils ------------------------------------------------------------------------------------
import { Layout, layoutFactory } from '@/utils/layouts.utils';
// -------------------------------------------------------------------------------------------------

export default layoutFactory({
  layout: Layout.Unknown
});
